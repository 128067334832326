import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {approveExpense, getExpense, getExpenseDetail} from "../expense.api"
import {RootState} from "../../../store"
import {
    Box,
    Button,
    CardContent,
    Container,
    IconButton,
    InputAdornment, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    TextField,
    Typography,
    useTheme
} from "@mui/material"
import {setParameterGetData} from "../expense.reducer"
import {
    CheckCircleOutline,
    Close,
    HighlightOffOutlined,
    PauseCircleFilledOutlined,
    Search,
    Undo,
    Visibility
} from "@mui/icons-material"
import CustomModal from "../../../shared/customModal/CustomModal"
import ExpenseReject from "./ExpenseReject"
import notificationSuccess from "../../../shared/notificationSuccess"
import Swal from "sweetalert2"
import ExpenseDetail from "./ExpenseDetail"
import Pagination from "../../../shared/Pagination"

export default function Expense() {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const {dataList, dataTotal, parameterGetData} = useSelector((state: RootState) => state.expense)

    const [search, setSearch] = useState('')
    const [idToReject, setIdToReject] = useState<string | null>(null)
    const [idToDetail, setIdToDetail] = useState<string | null>(null)

    useEffect(() => {
        dispatch(getExpense(parameterGetData))
    }, [dispatch, parameterGetData])

    const statusList = [
        {name: 'Pending', value: 0, icon: PauseCircleFilledOutlined, color: 'warning'},
        {name: 'Paid', value: 1, icon: CheckCircleOutline, color: 'success'},
        {name: 'Rejected', value: 2, icon: HighlightOffOutlined, color: 'error'},
    ]

    const handleApprove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        e.stopPropagation()
        await submitApprove(id)
    }

    const handleReject = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        e.stopPropagation()
        setIdToReject(id)
    }

    const submitApprove = async (id: string) => {
        await dispatch(approveExpense(id))
        await dispatch(getExpense(parameterGetData))
        await Swal.fire({
            title: 'Paid',
            icon: 'success',
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: 'OK',
            denyButtonText: 'Undo',
            reverseButtons: true,
            denyButtonColor: '#063058',
            confirmButtonColor: '#063058',
            showCloseButton: true,
            customClass: {
                closeButton: 'swal-close-button',
            }
        }).then(result => {
            if (result.isDenied) {
                // notificationSuccess("Billing request has been undone")
            }
        })
        if (idToDetail !== null) {
            dispatch(getExpenseDetail(idToDetail))
        }
    }

    const submitReject = async () => {
        setIdToReject(null)
        notificationSuccess("Billing request has been rejected")
        if (idToDetail !== null) {
            dispatch(getExpenseDetail(idToDetail))
        }
    }

    const handleDetail = (id: string) => {
        setIdToDetail(id)
    }

    return (
        <div className="project">
            <div className='menu-header scrolling'>
                <CustomModal
                    size="md"
                    open={idToReject !== null}
                    onClose={() => setIdToReject(null)}
                    title="Reject Billing Request"
                    component={<ExpenseReject id={idToReject} onCancel={() => setIdToReject(null)}
                                              onSubmit={() => submitReject()}/>}
                />
                <CustomModal
                    size="lg"
                    open={idToDetail !== null}
                    onClose={() => setIdToDetail(null)}
                    title="Detail Billing Request"
                    component={idToDetail !== null &&
                        <ExpenseDetail id={idToDetail} onApprove={() => submitApprove(idToDetail)}
                                       onReject={() => setIdToReject(idToDetail)} onClose={() => setIdToDetail(null)}/>}
                />
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                >
                    <Typography variant='h6'>Expenses</Typography>
                    <Box>
                        <TextField
                            fullWidth={false}
                            label='Search'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search/>
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    parameterGetData.filter !== '' ? (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={() => {
                                                    dispatch(
                                                        setParameterGetData({
                                                            ...parameterGetData,
                                                            skip: 0,
                                                            filter: '',
                                                        })
                                                    );
                                                    setSearch('');
                                                }}
                                            >
                                                <Close/>
                                            </IconButton>
                                        </InputAdornment>
                                    ) : undefined,
                            }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) =>
                                e.key === 'Enter' &&
                                dispatch(
                                    setParameterGetData({
                                        ...parameterGetData,
                                        skip: 0,
                                        filter: search,
                                    })
                                )
                            }
                        />
                        {search !== parameterGetData.filter && (
                            <Button
                                onClick={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            skip: 0,
                                            filter: search,
                                        })
                                    )
                                }
                            >
                                Search
                            </Button>
                        )}
                    </Box>
                </div>
            </div>
            <Container maxWidth="xl" sx={{marginTop: 1}}>
                <Box
                    display={"inline-flex"}
                    marginTop={1}
                    gap={"2px"}
                >
                    {statusList.map((status, i) => {
                        type colorType = "warning" | "success" | "error"
                        const color: colorType = status.color as colorType
                        return (
                            <Button
                                startIcon={<status.icon/>}
                                color={parameterGetData.status === i ? color : undefined}
                                onClick={() => dispatch(setParameterGetData({...parameterGetData, status: i}))}
                                sx={{
                                    color: parameterGetData.status === i ? undefined : "gray",
                                    borderTopLeftRadius: i === 0 ? 20 : 0,
                                    borderTopRightRadius: i === statusList.length - 1 ? 20 : 0,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    bgcolor: theme.palette.background.default,
                                    opacity: parameterGetData.status === i ? 1 : 0.5,
                                    padding: 2
                                }}
                                key={i}
                            >
                                {status.name}
                            </Button>
                        )
                    })}
                </Box>
                <Box sx={{
                    borderRadius: '0 20px 20px 20px',
                    backgroundColor: '#ffffff'
                }}>
                    <CardContent>
                        <TableContainer sx={{maxHeight: 400}}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Created Date</TableCell>
                                        <TableCell>Expense ID</TableCell>
                                        <TableCell>Fielder</TableCell>
                                        <TableCell>Expense Date</TableCell>
                                        <TableCell style={{textAlign: "right"}}>Amount</TableCell>
                                        <TableCell>Notes</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataList.map((data) => (
                                        <TableRow key={data.id} className="hover" onClick={() => handleDetail(data.id)}
                                                  sx={{":hover": {backgroundColor: '#80b8dd41'}, cursor: 'pointer'}}>
                                            <TableCell style={{minWidth: 100}}>{data.createdDateString}</TableCell>
                                            <TableCell>{data.docNumber}</TableCell>
                                            <TableCell>{data.createdBy}</TableCell>
                                            <TableCell style={{minWidth: 100}}>{data.processedDateString}</TableCell>
                                            <TableCell
                                                style={{textAlign: "right"}}>$ {data.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                            <TableCell>{data.notes}</TableCell>
                                            {parameterGetData.status === 0
                                                ? (
                                                    <TableCell>
                                                        <Button color="error" startIcon={<HighlightOffOutlined/>}
                                                                onClick={(e) => handleReject(e, data.id)}>Reject</Button>
                                                        <Button color="success" startIcon={<CheckCircleOutline/>}
                                                                onClick={(e) => handleApprove(e, data.id)}>Pay</Button>
                                                    </TableCell>
                                                )
                                                : (
                                                    <TableCell>
                                                        <Button color="primary" startIcon={<Undo/>}>Undo</Button>
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </CardContent>
                    <Box py={'10px'} px={'20px'}>
                        <div className='foot'>
                            <Pagination
                                skip={parameterGetData.skip}
                                take={parameterGetData.take}
                                dataTotal={dataTotal}
                                onChangePerPage={(value) =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            skip: 0,
                                            take: value,
                                        })
                                    )
                                }
                                onBack={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            skip: parameterGetData.skip - parameterGetData.take,
                                        })
                                    )
                                }
                                onNext={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            skip: parameterGetData.skip + parameterGetData.take,
                                        })
                                    )
                                }
                            />
                        </div>
                    </Box>

                </Box>


                {/*<table className="custom-table" style={{marginTop: 0, borderTopLeftRadius: 0}}>*/}
                {/*    <thead style={{display: 'table', width: '100%', tableLayout: 'fixed'}}>*/}
                {/*    <tr>*/}
                {/*        /!*<th>Project</th>*!/*/}
                {/*        /!*<th>Fielding Request</th>*!/*/}
                {/*        <th>Created Date</th>*/}
                {/*        <th>Fielder</th>*/}
                {/*        <th>Amount</th>*/}
                {/*        <th>Notes</th>*/}
                {/*        <th>Action</th>*/}
                {/*    </tr>*/}
                {/*    </thead>*/}
                {/*    <tbody style={{display: 'block', height: '400px', overflow: 'auto'}}>*/}
                {/*    {dataList.map((data) => (*/}
                {/*        <tr key={data.id} className="hover" onClick={() => handleDetail(data.id)}*/}
                {/*            style={{display: 'table', width: '100%', tableLayout: 'fixed'}}>*/}
                {/*            /!*<td>{data.project || '-'}</td>*!/*/}
                {/*            /!*<td>{data.fieldingRequest || '-'}</td>*!/*/}
                {/*            <td style={{minWidth: '200px'}}>{data.createdDateString}</td>*/}
                {/*            <td>{data.createdBy}</td>*/}
                {/*            <td style={{textAlign: "right"}}>$ {data.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>*/}
                {/*            <td>{data.notes}</td>*/}
                {/*            {parameterGetData.status === 0*/}
                {/*                ? (*/}
                {/*                    <td>*/}
                {/*                        <Button color="error" startIcon={<HighlightOffOutlined/>}*/}
                {/*                                onClick={(e) => handleReject(e, data.id)}>Reject</Button>*/}
                {/*                        <Button color="success" startIcon={<CheckCircleOutline/>}*/}
                {/*                                onClick={(e) => handleApprove(e, data.id)}>Pay</Button>*/}
                {/*                    </td>*/}
                {/*                )*/}
                {/*                : (*/}
                {/*                    <td>*/}
                {/*                        <Button color="primary" startIcon={<Undo/>}>Undo</Button>*/}
                {/*                    </td>*/}
                {/*                )*/}
                {/*            }*/}
                {/*        </tr>*/}
                {/*    ))}*/}
                {/*    </tbody>*/}
                {/*    <tfoot style={{display: 'table', width: '100%', tableLayout: 'fixed'}}>*/}
                {/*    <tr>*/}
                {/*        <td colSpan={7}>*/}
                {/*            <div className='foot'>*/}
                {/*                <Pagination*/}
                {/*                    skip={parameterGetData.skip}*/}
                {/*                    take={parameterGetData.take}*/}
                {/*                    dataTotal={dataTotal}*/}
                {/*                    onChangePerPage={(value) =>*/}
                {/*                        dispatch(*/}
                {/*                            setParameterGetData({*/}
                {/*                                ...parameterGetData,*/}
                {/*                                skip: 0,*/}
                {/*                                take: value,*/}
                {/*                            })*/}
                {/*                        )*/}
                {/*                    }*/}
                {/*                    onBack={() =>*/}
                {/*                        dispatch(*/}
                {/*                            setParameterGetData({*/}
                {/*                                ...parameterGetData,*/}
                {/*                                skip: parameterGetData.skip - parameterGetData.take,*/}
                {/*                            })*/}
                {/*                        )*/}
                {/*                    }*/}
                {/*                    onNext={() =>*/}
                {/*                        dispatch(*/}
                {/*                            setParameterGetData({*/}
                {/*                                ...parameterGetData,*/}
                {/*                                skip: parameterGetData.skip + parameterGetData.take,*/}
                {/*                            })*/}
                {/*                        )*/}
                {/*                    }*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    </tfoot>*/}
                {/*</table>*/}
            </Container>
        </div>
    )
}