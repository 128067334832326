import {
    Autocomplete,
    Box, Button,
    Card, CardActions,
    CardContent,
    CardHeader, IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useEffect, useState} from "react";
import moment from "moment";
import {getDashboardManager} from "../dashboard.api";
import CustomDatePicker from "../../../shared/customDatePicker/CustomDatePicker";
import {formatName} from "../../../shared/utils";
import {Close, Description, Search} from "@mui/icons-material";
import Papa from "papaparse"
import {getAccountFielder} from "../../account/account.api";
import {setParameterGetData} from "../../project/project.reducer";

export default function DashboardManager() {

    const dispatch = useDispatch<any>()

    const {dataManagerLogs} = useSelector((state: RootState) => state.dashboard)
    const {dataFielderList} = useSelector((state: RootState) => state.account)

    // delaying input search
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    const [filter, setFilter] = useState<string | undefined>("")
    const [searchText, setSearchText] = useState<string>("")
    const [dateStart, setDateStart] = useState(moment().add(-7, 'days').format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))
    const [fielderIdSelected, setFielderIdSelected] = useState<string | undefined>(undefined)
    const [statusSelected, setStatusSelected] = useState<number | undefined>(undefined)

    useEffect(() => {
        dispatch(getAccountFielder())
    }, [dispatch])

    useEffect(() => {
                dispatch(getDashboardManager({
                    filterStartDate: dateStart,
                    filterEndDate: dateEnd,
                    filter: filter,
                    fielderId: fielderIdSelected || "",
                    projectStatus: statusSelected
                }))

    }, [dispatch, dateStart, dateEnd, fielderIdSelected, filter, statusSelected])

    const handleExportCsv = () => {
        const csvData = dataManagerLogs.map((item) => ({
            Project: item.project,
            Request: item.fieldingRequest,
            AssignedFielder: item.assignedFielder,
            FieldingType: item.fieldingType,
            Poles: item.poles,
            Frontyard: item.frontyard,
            Backyard: item.backyard,
            NoAccessPoles: item.noAccessPole,
            Underground: item.underground,
            structures: item.structures,
            NoAccessStructures: item.noAccessStructures,
            StructuresNotInField: item.structuresNotInField,
            CompleteDate: item.date,
        }));
        const csvString = Papa.unparse(csvData, {header: true});
        const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `manager logs ${moment(dateStart).format('YYYY-MM-DD')} - ${moment(dateEnd).format('YYYY-MM-DD')}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const dataSorted = [...dataManagerLogs].sort((a, b) => moment(b.sortDate).diff(moment(a.sortDate)))

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                setFilter(e.target.value);
            }, 1000)
        );
    }

    return (
        <Card variant="outlined">
            <CardHeader
                sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}}
                title="Manager Log"
                action={(
                    <Box display={"flex"} flexWrap={"wrap"} justifyContent={"flex-end"} gap={1}>
                        <CustomDatePicker dateStart={dateStart} dateEnd={dateEnd} setDateStart={setDateStart}
                                          setDateEnd={setDateEnd}/>
                        <TextField
                            label='Search'
                            sx={{width: 200}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search/>
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    searchText !== "" ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setFilter("")
                                                    setSearchText("")
                                                }}
                                            >
                                                <Close />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : undefined
                            }}
                            value={searchText}
                            onChange={onSearchChange}
                        />
                        <Autocomplete
                            sx={{width: 200}}
                            options={dataFielderList}
                            getOptionLabel={(value: { id: string, fullName: string }) => formatName(value.fullName)}
                            onChange={(e, value) => setFielderIdSelected(value?.id || '')}
                            value={dataFielderList.find((data) => data.id === fielderIdSelected)}
                            renderInput={(params) => <TextField {...params} label="Filter Fielder"/>}
                        />
                        <Autocomplete
                            sx={{width: 200}}
                            options={[
                                {display: "Active", value: 0, order: 1},
                                {display: "Completed", value: 1, order: 2},
                                {display: "Archived", value: 2, order: 3},
                            ]}
                            getOptionLabel={(option) => option.display}
                            onChange={(e, value) => setStatusSelected(value?.value)}
                            renderInput={(params) => <TextField {...params} label="Filter Status"/>}
                        />
                    </Box>
                )}
            />
            <CardContent>
                <TableContainer sx={{height: 400}}>
                    <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Project</TableCell>
                            <TableCell>Request</TableCell>
                            <TableCell>Assigned Fielder</TableCell>
                            <TableCell>Fielding Type</TableCell>
                            <TableCell>Total Request</TableCell>
                            <TableCell>Frontyard</TableCell>
                            <TableCell>Backyard</TableCell>
                            <TableCell>No Access Poles</TableCell>
                            <TableCell>Underground</TableCell>
                            <TableCell>Structures</TableCell>
                            <TableCell>No Access Structures</TableCell>
                            <TableCell>Structure Not In Field</TableCell>
                            <TableCell>Last Update</TableCell>
                            <TableCell>Project Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSorted.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.project}</TableCell>
                                <TableCell sx={{whiteSpace: "nowrap"}}>{item.fieldingRequest}</TableCell>
                                <TableCell>{item.assignedFielder}</TableCell>
                                <TableCell>{item.fieldingType}</TableCell>
                                <TableCell>{item.poles}</TableCell>
                                <TableCell>{item.frontyard}</TableCell>
                                <TableCell>{item.backyard}</TableCell>
                                <TableCell>{item.noAccessPole}</TableCell>
                                <TableCell>{item.underground}</TableCell>
                                <TableCell>{item.structures}</TableCell>
                                <TableCell>{item.noAccessStructures}</TableCell>
                                <TableCell>{item.structuresNotInField}</TableCell>
                                <TableCell>{item.date}</TableCell>
                                <TableCell>{item.projectStatus}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Box marginTop={2}>
                    <Button startIcon={<Description />} onClick={handleExportCsv}>
                        Export to CSV
                    </Button>
                </Box>
            </CardActions>
        </Card>
    )
}