import { userInfo } from "../../shared/utils"
import { InterfaceFieldingMarkerList, InterfaceFieldingNotes } from "../fielding/fielding.interface"

export interface InterfaceProjectCreate {
    token: string
    companyId: string
    projectNumber: string
    description: string
    fpAddress: string
    fpCity: string
    customerCodeId: string | null
    isHotJob: boolean
    projectFieldingRequests: Array<{
        fieldingRequestId?: string
        fieldingRequestName: string
        fielderIds: Array<string>
        dueDateString: string
        colorCode: string
        customTypeId: string | null
        fieldingRequestMarkers: InterfaceFieldingMarkerList
        requestedStartDateString?: string
        requestedDueDateString?: string
    }>
    fielderCodeAndArea: string
    requestName: string
    projectName: string
    siteName: string
    customerJobNumber: string
    expenseProjectID: string
    poNumber: string
}
export const InitialProjectCreate: InterfaceProjectCreate = {
    token: "",
    companyId: "",
    projectNumber: "",
    description: "",
    fpAddress: "",
    fpCity: "",
    customerCodeId: null,
    isHotJob: false,
    projectFieldingRequests: [],
    fielderCodeAndArea: "",
    requestName: "",
    projectName: "",
    siteName: "",
    customerJobNumber: "",
    expenseProjectID: "",
    poNumber: "",
}

export interface InterfaceProjectMultiEdit {
    token: string
    companyId: string
    projectNumber: string
    description: string
    fpAddress: string
    fpCity: string
    customerCodeId: string | null
    isHotJob: boolean
    projectFieldingRequests: Array<{
        fieldingRequestId?: string
        fieldingRequestName: string
        fielderIds: Array<string>
        dueDateString: string
        colorCode: string
        customTypeId: string | null
        fieldingRequestMarkers: InterfaceFieldingMarkerList
    }>
    fielderCodeAndArea?: string
    requestName?: string
    projectName?: string
    siteName?: string
    customerJobNumber?: string
    expenseProjectID?: string
    poNumber?: string
}

export const InitialProjectMultiEdit: InterfaceProjectMultiEdit = {
    token: "",
    companyId: "",
    projectNumber: "",
    description: "",
    fpAddress: "",
    fpCity: "",
    customerCodeId: null,
    isHotJob: false,
    projectFieldingRequests: [],
    fielderCodeAndArea: undefined,
    requestName: undefined,
    projectName: undefined,
    siteName: undefined,
    customerJobNumber: undefined,
    expenseProjectID: undefined,
    poNumber: undefined,
}

export interface InterfaceUpdateProgress {
    token: string
    fieldingRequestId: string
    customTypeId: string
    progressPercentage: number
    sliderProgressPercentage: number
    fieldingRequestActualFielding: string
    frontyard: number
    backyard: number
    noAccessPole: number
    underground: number
    structures: number
    noAccessStructures: number
    structuresNotInField: number
}

export const initialUpdateProgress: InterfaceUpdateProgress = {
    token: "",
    fieldingRequestId: "",
    customTypeId: "",
    progressPercentage: 0,
    sliderProgressPercentage: 0,
    fieldingRequestActualFielding: '0',
    frontyard: 0,
    backyard: 0,
    noAccessPole: 0,
    underground: 0,
    structures: 0,
    noAccessStructures: 0,
    structuresNotInField: 0
}

export interface InterfaceProjectEdit {
    token: string
    id: string
    projectNumber: string
    description: string
    fpAddress: string
    fpCity: string
    customerCodeId: string | null
    isHotJob: boolean
    requestName: string
    projectName: string
    siteName: string
    customerJobNumber: string
    expenseProjectID: string
    poNumber: string
    fielderCodeAndArea: string
}

export interface InterfaceProjectGetParams {
    skip: number
    take: number
    token: string
    companyId: string
    filter: string
    projectStatus: 0 | 1 | 2
    sortField: string
    sortType: 0 | 1
    fielderIds: Array<string>,
    isHotJob?: boolean,
    fieldingStatus?: number
}
export const InitialProjectGetParams: InterfaceProjectGetParams = {
    skip: 0,
    take: 50,
    token: userInfo().token,
    companyId: userInfo().user.companyId,
    filter: "",
    projectStatus: 0,
    sortField: "",
    sortType: 0,
    fielderIds: []
}

export interface InterfaceProjectData {
    id: string
    projectNumber: string
    fpAddress: string
    fpCity: string
    date: string
    dueDateString: string
    fpProjectStatus: number
    fpProjectStatusString: string
    customerCodeId: string | null
    customerCodeString: string
    firstMarkerLocation: string
    createdByName: string
    createdById: string
    createdDateString: string
    archiveDateString: string
    completeDate: string
    completeDateString: string
    isHotJob: boolean,
    fieldingRequestCount: number,
    projectFielderIds: string[],
    description: string,
    requestName: string,
    projectName: string,
    siteName: string,
    customerJobNumber: string,
    expenseProjectID: string,
    poNumber: string,
    fielderCodeAndArea: string | null,
    totalMarkerRequest: number,
}
export interface InterfaceProjectDataList extends Array<InterfaceProjectData> { }

export interface InterfaceProjectEnum {
    projectDescription: Array<{
        display: string
        value: number
        order: number
    }>
    projectStatus: Array<{
        display: string
        value: number
        order: number
    }>
}
export const InitialProjectEnum: InterfaceProjectEnum = {
    projectDescription: [],
    projectStatus: []
}

export interface InterfaceProjectDetailFielding {
    projectId?: string
    fieldingRequest: string
    fieldingRequestId: string
    fielderIds: Array<string>
    fielderNames: Array<string>
    dueDateString: string
    colorCode: string
    customTypeId: string
    customTypeString: string
    status: string
    requestedStartDateString: string
    requestedDueDateString: string
    createdByName: string
    hasRatedAllFielders: boolean,
    modifiedDateString: string,
    milesTotal: number,
    fieldingRequestMarkers: Array<InterfaceFieldingRequestMarker>
    fieldingRequestNotes: Array<InterfaceFieldingNotes>
}
export interface InterfaceFieldingRequestMarker {
        id: string
        lineData: string
        shapeData: string
        dotLongLat: string
        colorCode: string
        subject: string
        notes: string
        timeOffset: number
        requestedFielding: string
        requestedAmountType: 0 | 1
        actualFielding: string
        customTypeId: string
        customTypeString: string
        requestedAmountTypeString: string
        progressPercentage: number
        frontyard: number
        backyard: number
        noAccessPole: number
        underground: number
        structures: number
        noAccessStructures: number
        structuresNotInField: number
}
export interface InterfaceProjectDetailAttachmentList
    extends Array<{
        fileName: string
        filePath: string
        id: string
        projectId: string
    }> { }
export interface InterfaceProjectDetail {
    id: string
    projectNumber: string
    customerCodeId: string
    description: string
    fpAddress: string
    fpCity: string
    date: string
    dueDateString: string
    fpProjectStatus: number
    fpProjectStatusString: string
    projectAttachments: InterfaceProjectDetailAttachmentList
    projectLinks: Array<{
        link: string
    }>
    projectFieldingRequests: Array<InterfaceProjectDetailFielding>,
    isHotJob: boolean
    fielderCodeAndArea: string
    requestName: string
    projectName: string
    siteName: string
    customerJobNumber: string
    expenseProjectID: string
    poNumber: string
}
export const InitialProjectDetail: InterfaceProjectDetail = {
    id: "",
    projectNumber: "",
    customerCodeId: "",
    description: "",
    fpAddress: "",
    fpCity: "",
    date: "",
    dueDateString: "",
    fpProjectStatus: 0,
    fpProjectStatusString: "",
    projectAttachments: [],
    projectLinks: [],
    projectFieldingRequests: [],
    isHotJob: false,
    fielderCodeAndArea: "",
    requestName: "",
    projectName: "",
    siteName: "",
    customerJobNumber: "",
    expenseProjectID: "",
    poNumber: ""
}

export interface InterfaceProjectCustomerCode {
    id: string
    value: string
}
export interface InterfaceProjectCustomerCodeList extends Array<InterfaceProjectCustomerCode> { }

export interface InterfaceProjectLinkAppend {
    projectId: string
    links: Array<string>
}

export interface InterfaceProjectFileLocalLIst
    extends Array<{
        file: any
        uploadProgress: number
        objectUrl: any
    }> { }

export interface InterfaceProjectFieldingAdd {
    fieldingRequestName: string
    fielderIds: Array<string>
    dueDateString: string | null
    colorCode: string
    customTypeId: string | null
    fieldingRequestMarkers: Array<{
        lineData: string
        shapeData: string
        dotLongLat: string
        colorCode: string
        subject: string
        notes: string
        timeOffset: number
    }>
    token: string
    projectId: string
}
