import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid, IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {getDashboardActivity, getDashboardActivityType} from "../dashboard.api";
import CustomDatePicker from "../../../shared/customDatePicker/CustomDatePicker";
import {formatName} from "../../../shared/utils";
import {CheckCircleOutline, Close, Description, HighlightOffOutlined, Search, Visibility} from "@mui/icons-material";
import Papa from "papaparse"
import {getAccountFielder} from "../../account/account.api";
import CustomModal from "../../../shared/customModal/CustomModal";
import ExpenseReject from "../../expense/component/ExpenseReject";
import ExpenseDetail from "../../expense/component/ExpenseDetail";
import notificationSuccess from "../../../shared/notificationSuccess";
import {approveExpense, getExpense, getExpenseDetail} from "../../expense/expense.api";
import Swal from "sweetalert2";

export default function DashboardExpenses() {

    const dispatch = useDispatch<any>()

    const {dataActivityLogs, activityTypeList} = useSelector((state: RootState) => state.dashboard)
    const {dataFielderList} = useSelector((state: RootState) => state.account)

    // delaying input search
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    const [filter, setFilter] = useState<string | undefined>(undefined)
    const [searchText, setSearchText] = useState<string>("")

    const [dateStart, setDateStart] = useState(moment().add(-7, 'days').format('MM/DD/YYYY'))
    const [dateEnd, setDateEnd] = useState(moment().format('MM/DD/YYYY'))
    const [fielderIdSelected, setFielderIdSelected] = useState<string | undefined>(undefined)
    const [typeSelected, setTypeSelected] = useState<number | undefined>(undefined)

    const [idToReject, setIdToReject] = useState<string | null>(null)
    const [idToDetail, setIdToDetail] = useState<string | null>(null)

    useEffect(() => {
        dispatch(getDashboardActivityType())
        dispatch(getAccountFielder())
    }, [dispatch])

    // useEffect(() => {
    //     dispatch(getDashboardActivity({ filterStartDate: dateStart, filterEndDate: dateEnd, activityType: typeSelected, fielderId: fielderIdSelected || "" }))
    // }, [dispatch, dateStart, dateEnd, fielderIdSelected, typeSelected])

    const {dataList, dataTotal, parameterGetData} = useSelector((state: RootState) => state.expense);

    useEffect(() => {
        console.log(dateStart, dateEnd);
        dispatch(getExpense({
            skip: 0,
            take: 200,
            startDateString: dateStart,
            endDateString: dateEnd,
            filter: filter as string,
            status: 0
        }))
    }, [dispatch, dateStart, dateEnd, filter])

    const handleExportCsv = () => {
        const csvData = dataActivityLogs.map((item) => ({
            Request: item.fieldingRequest,
            AssignedFielder: item.name,
            LastUpdate: item.activityDateString,
            LastAction: item.activityTypeString,
            Mileage: item.miles,
            Hours: item.durationString
        }));
        const csvString = Papa.unparse(csvData, {header: true});
        const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `activity logs ${moment(dateStart).format('YYYY-MM-DD')} - ${moment(dateEnd).format('YYYY-MM-DD')}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const dataSorted = [...dataList].sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)))

    const submitApprove = async (id: string) => {
        await dispatch(approveExpense(id))
        await dispatch(getExpense(parameterGetData))
        await Swal.fire({
            title: 'Paid',
            icon: 'success',
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: 'Undo',
            denyButtonColor: '#063058',
            showCloseButton: true,
            customClass: {
                closeButton: 'swal-close-button',
            }
        }).then(result => {
            if (result.isDenied) {
                // notificationSuccess("Billing request has been undone")
            }
        })
        if (idToDetail !== null) {
            dispatch(getExpenseDetail(idToDetail))
        }
    }

    const submitReject = async () => {
        setIdToReject(null)
        notificationSuccess("Billing request has been rejected")
        if (idToDetail !== null) {
            dispatch(getExpenseDetail(idToDetail))
        }
    }

    const handleDetail = (id: string) => {
        setIdToDetail(id)
    }

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                setFilter(e.target.value);
            }, 1000)
        );
    }

    return (
        <Card variant="outlined">
            <CustomModal
                size="md"
                open={idToReject !== null}
                onClose={() => setIdToReject(null)}
                title="Reject Billing Request"
                component={<ExpenseReject id={idToReject} onCancel={() => setIdToReject(null)}
                                          onSubmit={() => submitReject()}/>}
            />
            <CustomModal
                size="lg"
                open={idToDetail !== null}
                onClose={() => setIdToDetail(null)}
                title="Detail Billing Request"
                component={idToDetail !== null &&
                    <ExpenseDetail id={idToDetail} onApprove={() => submitApprove(idToDetail)}
                                   onReject={() => setIdToReject(idToDetail)} onClose={() => setIdToDetail(null)}/>}
            />
            <CardHeader
                sx={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}}
                title="Expenses"
                action={(
                    <Box display={"flex"} flexWrap={"wrap"} justifyContent={"flex-end"} gap={1}>
                        <CustomDatePicker dateStart={dateStart} dateEnd={dateEnd} setDateStart={setDateStart}
                                          setDateEnd={setDateEnd} format={'MM/DD/YYYY'}/>
                        <TextField
                            label='Search Notes'
                            sx={{width: 200}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search/>
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    searchText !== "" ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setFilter("")
                                                    setSearchText("")
                                                }}
                                            >
                                                <Close/>
                                            </IconButton>
                                        </InputAdornment>
                                    ) : undefined
                            }}
                            value={searchText}
                            onChange={onSearchChange}
                        />
                    </Box>
                )}
            />
            <CardContent>
                <TableContainer sx={{height: 400}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Expenses ID</TableCell>
                                <TableCell>Create Date</TableCell>
                                <TableCell>Fielder</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Notes</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataSorted.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.docNumber}</TableCell>
                                    <TableCell>{item.createdDateString}</TableCell>
                                    <TableCell sx={{whiteSpace: "nowrap"}}>{item.createdBy}</TableCell>
                                    <TableCell style={{
                                        textAlign: "right",
                                        width: "100px"
                                    }}>$ {item.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                    <TableCell>{item.notes}</TableCell>
                                    <TableCell>
                                        <Button color="primary" startIcon={<Visibility/>}
                                                onClick={() => handleDetail(item.id)}>View</Button>
                                        <Button color="error" startIcon={<HighlightOffOutlined/>}>Reject</Button>
                                        <Button color="success" startIcon={<CheckCircleOutline/>}>Pay</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            {/*<CardActions>*/}
            {/*    <Box marginTop={2}>*/}
            {/*        <Button startIcon={<Description />} onClick={handleExportCsv}>*/}
            {/*            Export to CSV*/}
            {/*        </Button>*/}
            {/*    </Box>*/}
            {/*</CardActions>*/}
        </Card>
    )
}