import {Box, Button, IconButton, LinearProgress, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {FormEvent, useEffect, useState} from "react";
import {InitialExpenseCreate, InterfaceExpenseCreate} from "../expense.interface";
import {createExpense, generateDocNumber} from "../expense.api";
import notificationSuccess from "../../../shared/notificationSuccess";
import {Dropzone, setNextUploadStatus} from "@dropzone-ui/react";
import {Cancel, Check} from "@mui/icons-material";
import Papa from "papaparse";
import {userInfo} from "../../../shared/utils";
import axios from "axios";
import {baseUrl} from "../../../state";
import notificationError from "../../../shared/notificationError";
import {RootState} from "../../../store";
import moment from "moment";

export default function ExpenseCreate(props: { onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { newDocNumber } = useSelector(
        (state: RootState) => state.expense
    );

    const [value, setValue] = useState<InterfaceExpenseCreate>(InitialExpenseCreate)
    const [filesQueue, setFilesQueue] = useState<any>([])
    const [isUploading, setIsUploading] = useState<boolean>(false)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [uploadStep, setUploadStep] = useState<boolean>(false)
    const [isCreated, setIsCreated] = useState<boolean>(false)
    const [currentBillingId, setCurrentBillingId] = useState<string | undefined>(undefined)

    const handleSubmit = async (e: FormEvent) => {
        setIsLoading(true)
        e.preventDefault()
        dispatch(createExpense({...value, docNumber: newDocNumber, processedDate: value.processedDate === "" ? null : value.processedDate}))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setIsCreated(true)
                    setIsLoading(false)
                    setCurrentBillingId(response?.data?.data?.id)
                    setUploadStep(true)
                } else {
                    setIsCreated(false)
                    setIsLoading(false)
                    setCurrentBillingId(undefined)
                }
            })
            .catch((error: any) => {
                console.log(error)
                setIsCreated(false)
                setIsLoading(false)
                setCurrentBillingId(undefined)
            })
    }

    useEffect(() => {
        dispatch(generateDocNumber())
    }, []);

    useEffect(() => {
        if (currentBillingId !== undefined && (!isUploading && filesQueue.length > 0)) {
            setIsUploading(true)
            uploading(currentBillingId)
        }
    }, [filesQueue, currentBillingId])

    const uploading = async (billingId: string) => {
        let filesLocal: any = filesQueue
        let filesToUpload: any = filesLocal.filter((file: any) => file.uploadProgress === 0)
        if (filesToUpload.length > 0) {
            for (const i in filesToUpload) {
                try {
                    let data = new FormData();
                    data.append("billingId", billingId)
                    data.append("token", userInfo().token)
                    data.append("file", filesToUpload[i].file)
                    await axios.post(`${baseUrl}/newapi/Billing/UploadAttachments`, data, {
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
                            filesLocal = filesLocal.map((f: any) => {
                                if (f.name === filesToUpload[i].name) {
                                    return {
                                        ...f,
                                        uploadProgress: percentCompleted
                                    }
                                }
                                return f
                            })
                            setFilesQueue(filesLocal)
                        }
                    });
                } catch (error: any) {
                    console.log(error)
                    notificationError(error.response)
                }
            }
        }
        setIsUploading(false)
        notificationSuccess("Successfully add expense attachment")
    }

    const onChangeImage = async (filesLocal: any) => {
        if (filesLocal.length > 0) {
            if (filesLocal.length > 0) {
                setFilesQueue([
                    ...filesQueue,
                    ...Array.from(filesLocal).map((file: any) => {
                        return {
                            name: file.name,
                            uploadProgress: 0,
                            file: file.file
                        }
                    })
                ])
            }
        }
    }

    return (

        <>
            {
                uploadStep ? (
                    <Box component={"form"} display={"flex"} flexDirection={"column"} gap={1} onSubmit={handleSubmit}>
                        <Box>
                            <Dropzone onChange={onChangeImage} header={false} accept="all/*" disabled={isUploading}/>
                            {filesQueue.map((file: any) => (
                                <Box key={file.name}>
                                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        {file.name}
                                        <IconButton>
                                            {file.uploadProgress < 100
                                                ? (
                                                    <Cancel/>
                                                )
                                                : (
                                                    <Check color="success"/>
                                                )
                                            }
                                        </IconButton>
                                    </Box>
                                    <LinearProgress variant="determinate" value={file.uploadProgress}/>
                                </Box>
                            ))}
                        </Box>
                        <Button type="button" variant="contained" fullWidth color="primary"
                                onClick={() => props.onSubmit()}>Done</Button>
                    </Box>
                ) : (
                    <Box component={"form"} display={"flex"} flexDirection={"column"} gap={1} onSubmit={handleSubmit}>
                        <TextField label="Expense ID" value={newDocNumber} InputProps={{ readOnly: true }}/>
                        <TextField label="Amount" type={'number'} value={parseInt(value.amount || '0')} onChange={(e) => setValue({
                            ...value,
                            amount: String(parseInt(e.target.value || '0'))
                        })}/>
                        <TextField
                            fullWidth={false}
                            value={moment(value.processedDate).format('YYYY-MM-DD')}
                            onChange={(e) => setValue({
                                ...value,
                                processedDate: moment(e.target.value).toISOString()
                            })}
                            type="date"
                            name="dueDateString"
                            label="Expense Date"
                            InputLabelProps={{shrink: true}}
                        />
                        <TextField label="Notes" multiline minRows={3} value={value?.notes}
                                   onChange={(e) => setValue({...value, notes: e.target.value})}/>

                        {
                            isCreated ? (
                                <Button type="button" variant="contained" fullWidth color="primary"
                                        onClick={() => setUploadStep(true)}>Next</Button>
                            ) : (
                                <Button type="submit" variant="contained" fullWidth color="primary"
                                        onClick={handleSubmit} disabled={isLoading}>Create</Button>
                            )
                        }
                    </Box>
                )
            }
        </>
    )
}